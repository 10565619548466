import AlertErrorContent from "@/components/Alert/ErrorContent";
import { alertDrawer, modalDialog } from "@/components/layout";
import { SOCIAL_MEDIA_DATA } from "@/components/Profile/const/socialMedia";
import { putAuthMe } from "@/service/auth";
import { useMutation } from "@tanstack/react-query";
import { useState, useCallback } from "react";

const DEFAULT_INPUT_STATE = {
  text: "",
  error: null,
};

const MIN_NICKNAME_LENGTH = 3;
const ERROR_CODE = {
  username: "4005",
  email: "4006",
};

export default function useAccountSetupForm(onConfirm = () => {}) {
  const [socialMediaState, setSocialMediaState] = useState({
    id: SOCIAL_MEDIA_DATA[0].id,
    value: "",
  });
  const [nicknameState, setNicknameState] = useState(DEFAULT_INPUT_STATE);
  const [emailState, setEmailState] = useState(DEFAULT_INPUT_STATE);
  const [interestState, setInterestState] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  const {
    mutate: saveMyProfile,
    isPending: isLoadingForm,
    isError,
  } = useMutation({
    mutationFn: putAuthMe,
    onError: (e) => {
      const errors = e?.response?.errors || [];
      const errorCodes = errors.map((error) => error.error_id);
      const ARR_ERROR_CODES = [ERROR_CODE.email, ERROR_CODE.username];

      const isErrorCodeInvalid = !ARR_ERROR_CODES.some((item) =>
        errorCodes.includes(item),
      );

      if (isErrorCodeInvalid) {
        modalDialog.value = { ...modalDialog.value, backgroundClose: true };
        alertDrawer.value = [
          {
            show: true,
            type: "error",
            content: (
              <AlertErrorContent
                errorMessage={
                  errors?.[0]?.message ||
                  "Something went wrong, please try again"
                }
              />
            ),
          },
        ];
        return;
      }

      errors.forEach((error) => {
        const updatedState = {
          error: error.message,
        };
        if (error.error_id === ERROR_CODE.username) {
          updateNickname(updatedState);
        } else if (error.error_id === ERROR_CODE.email) {
          updateEmail(updatedState);
        }
      });
    },
    onSuccess: () => {
      modalDialog.value = {
        ...modalDialog.value,
        backgroundClose: true,
        onClose: onConfirm,
      };
      setSuccess(true);
    },
  });

  const updateNickname = useCallback(
    (obj = { text: "", error: "" }) => {
      setNicknameState((curr) => ({ ...curr, ...obj }));
    },
    [setNicknameState],
  );

  const updateEmail = useCallback(
    (obj = { text: "", error: "" }) => {
      setEmailState((curr) => ({ ...curr, ...obj }));
    },
    [setEmailState],
  );

  const isDisabledForm =
    isLoadingForm ||
    nicknameState.text === "" ||
    emailState.text === "" ||
    socialMediaState.value === "" ||
    interestState === "";

  const validateFormOnSubmit = () => {
    const isNicknameValid = nicknameState.text.length >= MIN_NICKNAME_LENGTH;

    if (!isNicknameValid) {
      updateNickname({
        error: "Please enter at least 3 characters to continue.",
      });
    }
    return isNicknameValid;
  };

  const submitForm = () => {
    if (isDisabledForm) return;

    if (validateFormOnSubmit()) {
      saveMyProfile({
        email: emailState.text,
        username: nicknameState.text,
        [socialMediaState.id]: socialMediaState.value,
        interest: interestState,
      });
    }
  };

  return {
    isLoadingForm,
    isDisabledForm,
    nicknameState,
    emailState,
    socialMediaState,
    isSuccess,
    isError,
    interestState,
    setSocialMediaState,
    updateNickname,
    updateEmail,
    submitForm,
    setInterestState,
  };
}
