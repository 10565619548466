import { cn } from "@/utils/tailwind";

export default function TextInput({
  value,
  isSuccess,
  errorText,
  infoText,
  placeholder,
  label,
  maxLength,
  handleChange = () => {},
  handleBlur = () => {},
  size = "md",
  prefix = null,
  isReadOnly = false,
}) {
  const onChange = (e) => {
    if (isReadOnly) return;
    handleChange(e);
  };

  const onBlur = (e) => {
    if (isReadOnly) return;
    handleBlur(e);
  };
  return (
    <>
      <p className="font-mono_medium mb-2 font-mono text-[16px] leading-5">
        {label}
      </p>
      <div
        className={cn(
          "relative flex w-full items-center border-1 border-byteBlack-1000/30 text-[16px] md:h-[48px]",
          size === "sm" ? "h-[36px]" : "h-[48px]",
          {
            "border-1 border-[#FF0307]": errorText,
            "bg-byteBlack-1000/10": isReadOnly,
          },
        )}
      >
        {prefix && prefix}

        <input
          className="w-full !bg-transparent px-4 text-[14px] leading-5 outline-none placeholder:font-normal placeholder:tracking-wide placeholder:text-byteBlack-1000/30 md:text-[16px] md:leading-6"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          maxLength={maxLength}
          readOnly={isReadOnly}
        />
        {isSuccess && (
          <span className="material-symbols-outlined px-4 !text-[24px] text-[#139104]">
            check_circle
          </span>
        )}
      </div>
      {errorText ? (
        <div className="mt-2 flex items-center space-x-1 text-[14px] leading-5 tracking-wide text-[#D70003]">
          <span className="material-symbols-outlined material-no-fill !text-[18px] !leading-5 text-[#D70003]">
            error
          </span>
          <span className="[&:first-letter]:uppercase">{errorText}</span>
        </div>
      ) : (
        <>
          {maxLength && value !== "" && !isReadOnly ? (
            <div className="mt-2 flex items-center space-x-1 text-[14px] leading-5 tracking-wide text-byteBlack-1000/50">
              <span>
                {value.length}/{maxLength} Character
              </span>
            </div>
          ) : (
            <>
              {infoText && (
                <div className="mt-2 flex items-center space-x-1 text-[14px] leading-5 tracking-wide text-byteBlack-1000/50">
                  <span>{infoText}</span>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
