import React, { useCallback, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { Modal, ModalContent, ModalBody } from "@nextui-org/react";
import { useAccount } from "wagmi";
import { signal } from "@preact/signals-react";
import SignWallet from "./Dialog/SignWallet";
import Alert from "./Alert";
import { isMobile } from "react-device-detect";
import LoaderComponent from "./Loader";
import { ReactLenis } from "lenis/react";
import { triggerAfterSignWallet, triggerAfterConnect } from "./Dialog/signal";
import { wagmiConnection } from "@/signal/layout";
import { cn } from "@/utils/tailwind";
import { AuthContextProvider } from "@/contexts/auth/provider";
import CountdownTrialProvider from "@/contexts/countdownTrial/provider";

export const bythenAccount = signal();

export const showLoader = signal(false);

export const alertDrawer = signal([]);

export const INITIAL_MODAL_DIALOG = {
  show: false,
  body: null,
  backgroundClose: true,
  hideCloseButton: false,
  backdrop: "blur",
  baseClass: "",
  bodyClass: "",
  closeButtonClass: "",
  wrapperClassName: "",
  backdropClassName: "",
  isKeyboardDismissDisabled: false,
  onOk: () => {},
  onCancel: () => {},
  onClose: () => {},
};

export const modalDialog = signal(INITIAL_MODAL_DIALOG);

export const closeModalDialog = () => {
  modalDialog.value = {
    ...modalDialog.value,
    show: false,
  };
  setTimeout(() => {
    modalDialog.value = INITIAL_MODAL_DIALOG;
  }, 200);
};

const getCookieAuth = () => {
  if (!Cookies.get("BYTHEN_AUTH")) return null;

  try {
    const authCookie = JSON.parse(Cookies.get("BYTHEN_AUTH"));
    return authCookie;
  } catch (e) {
    return null;
  }
};
function LayoutBody({ onSuccessSignIn = null, children }) {
  const { address, status } = useAccount();
  const startLoadingRef = useRef(null);

  useEffect(() => {
    if (status === "reconnecting" || status === "connecting") {
      startLoadingRef.current = true;
    } else if (startLoadingRef.current) {
      wagmiConnection.value = status === "connected" || !!address;
      startLoadingRef.current = false;
    }
  }, [status, address]);

  useEffect(() => {
    //Trigger if wagmi connected
    const cookieAuth = getCookieAuth();
    if (address) {
      window.localStorage.removeItem("wagmi.io.metamask.disconnected");

      if (triggerAfterConnect.value) {
        triggerAfterSignWallet.value = true;
      }

      const generateSignWalletModal = () => {
        if (cookieAuth && cookieAuth?.wallet_address) {
          bythenAccount.value = { ...cookieAuth, fetch_reward: true };
          return;
        }
        modalDialog.value = {
          show: true,
          body: <SignWallet onSuccessSignIn={onSuccessSignIn} />,
          backgroundClose: false,
          hideCloseButton: true,
          backdrop: "opaque",
          placement: "center",
          baseClass:
            "md:w-full max-h-[95vh] md:max-h-[90vh] md:rounded-none w-[95vw] max-w-[458px] rounded-none bg-[#FBFAF8] shadow-[0px_0px_0px_4px_#0000000A,0px_0px_0px_2px_#00000014]",
          bodyClass:
            "md:rounded-none rounded-none bg-[#FBFAF8] pt-14 px-8 pb-8 gap-0",
        };
      };

      generateSignWalletModal();
    } else {
      if (cookieAuth && cookieAuth?.wallet_address) {
        bythenAccount.value = { ...cookieAuth, fetch_reward: true };
      }
    }
  }, [address, onSuccessSignIn]);

  const handleCloseModal = useCallback(() => {
    closeModalDialog();
    if (modalDialog.value?.onClose) {
      modalDialog.value.onClose();
    }
  }, []);

  return (
    <>
      {showLoader.value && <LoaderComponent />}

      {alertDrawer.value.length ? <Alert /> : null}

      {children}

      <Modal
        isOpen={modalDialog.value.show}
        placement={
          modalDialog.value.placement
            ? modalDialog.value.placement
            : isMobile
              ? "bottom"
              : "center"
        }
        isDismissable={modalDialog.value.backgroundClose}
        onClose={handleCloseModal}
        scrollBehavior={"inside"}
        backdrop={modalDialog.value.backdrop}
        hideCloseButton={modalDialog.value.hideCloseButton}
        isKeyboardDismissDisabled={modalDialog.value.isKeyboardDismissDisabled}
        classNames={{
          wrapper: cn("w-full", modalDialog.value.wrapperClassName),
          backdrop: cn(
            "backdrop-brightness-50",
            modalDialog.value.backdropClassName,
          ),
          base: cn(
            "overflow-y-visible max-h-[70vh] md:max-h-[80vh] w-full max-w-full max-w-[542px] m-0 md:mx-6 sm:my-0 rounded-b-none md:rounded-b-3xl rounded-t-3xl md:rounded-t-3xl",
            modalDialog.value.baseClass,
          ),
          body: cn(
            `px-[28px] py-[84px] pb-[28px] rounded-b-none md:rounded-b-3xl rounded-t-3xl md:rounded-t-3xl bg-pampas justify-center items-center`,
            modalDialog.value.bodyClass,
          ),
          closeButton: cn(
            "hover:bg-white/5 active:bg-white/10 [&>svg]:w-[24px] [&>svg]:h-[24px] p-3",
            modalDialog.value.closeButtonClass,
          ),
        }}
      >
        <ModalContent>
          <ModalBody>{modalDialog.value.body}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function Layout({
  children,
  onSuccessSignIn = null,
  serverToken,
  initialIsByteExist = null,
  onFreeTrialModalClose,
}) {
  return (
    <ReactLenis root>
      <AuthContextProvider
        serverToken={serverToken}
        initialIsByteExist={initialIsByteExist}
      >
        <LayoutBody onSuccessSignIn={onSuccessSignIn}>
          <CountdownTrialProvider onFreeTrialModalClose={onFreeTrialModalClose}>
            {children}
          </CountdownTrialProvider>
        </LayoutBody>
      </AuthContextProvider>
    </ReactLenis>
  );
}
