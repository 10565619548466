import { useCallback, useMemo, useState } from "react";
import { CountdownTrialContext } from "./context";
import { getTrialBytes, putTrialBytes } from "@/service/store";
import ModalEndTrial from "./ModalEndTrial";
import { getModalProps } from "@/components/Modal/ModalDialog/const";
import { useMutation, useQuery } from "@tanstack/react-query";
import { modalDialog } from "@/components/layout";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAuthContext } from "../auth/context";
import { FEATURE_FLAG } from "@/utils/featureFlag";

const getStorePdpUrl = (data) => {
  const { trial } = data || {};
  // const tokenId = trial?.original_token_id;
  const symbol = trial?.original_token_symbol ?? "";

  return `/store/${symbol}`;
};

const CountdownTrialProvider = ({ children, onFreeTrialModalClose }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const { isAuthorized, isUnauthorized } = useAuthContext();
  const [isShowEndTrialModal, setIsShowEndTrialModal] = useState(false);
  const [isFreeTrialAllowed, setFreeTrialAllowed] = useState(false);
  const router = useRouter();

  const {
    data: trialData,
    isFetched: isTrialFetched,
    isLoading: isTrialLoading,
  } = useQuery({
    queryKey: ["getTrialBytes", isAuthorized],
    enabled: isAuthorized && FEATURE_FLAG.FREE_TRIAL,
    queryFn: getTrialBytes,
    select: useCallback((data) => {
      const countdown = data?.trial?.trial_countdown || 0;
      setTimeLeft(countdown);

      const isTrialFinished = data?.trial?.is_trial_finished || false;
      const isModalEndShowed = data?.trial?.is_end_dialogue_displayed === false;

      setIsShowEndTrialModal(isTrialFinished && isModalEndShowed);
      setFreeTrialAllowed(data?.trial?.account_id === 0);
      return data;
    }, []),
  });

  useEffect(() => {
    setTimeLeft(0);
    setIsShowEndTrialModal(false);
    setFreeTrialAllowed(false);
  }, [isUnauthorized]);

  const { mutate: updateTrialByte } = useMutation({
    mutationFn: putTrialBytes,
  });

  const resetFreeTrial = useCallback(() => {
    setIsShowEndTrialModal(false);
    modalDialog.value = {
      ...modalDialog.value,
      show: false,
    };
    updateTrialByte({
      is_end_dialogue_displayed: true,
    });
  }, [updateTrialByte]);

  const onCloseModalEndTrial = useCallback(() => {
    resetFreeTrial();
    if (onFreeTrialModalClose) {
      onFreeTrialModalClose();
    } else {
      router.push("/store");
    }
  }, [onFreeTrialModalClose, router, resetFreeTrial]);

  const onBuy = useCallback(() => {
    resetFreeTrial();
    const storePdpUrl = getStorePdpUrl(trialData);
    router.push(storePdpUrl);
  }, [router, trialData, resetFreeTrial]);

  useEffect(() => {
    if (isShowEndTrialModal) {
      modalDialog.value = {
        ...getModalProps({
          backgroundClose: false,
          bodyClassName: "p-0 md:p-0",
          isMobileBottomSheet: false,
          isDesktopWidthFixed: false,
          hideCloseButton: true,
        }),
        body: <ModalEndTrial onBuy={onBuy} onClose={onCloseModalEndTrial} />,
        isKeyboardDismissDisabled: true,
      };
    }
  }, [isShowEndTrialModal, onCloseModalEndTrial, onBuy]);

  useEffect(() => {
    if (timeLeft <= 0) {
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          setIsShowEndTrialModal(true);
        }
        return Math.max(prevTime - 1, 0);
      }); // Reduce time by 1 second
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [timeLeft]); // Rely on the internal state

  const value = useMemo(
    () => ({
      timeLeft,
      isFreeTrialAllowed,
      isTrialFetched,
      isTrialLoading,
      trialData,
    }),
    [timeLeft, isFreeTrialAllowed, isTrialFetched, isTrialLoading, trialData],
  );
  return (
    <CountdownTrialContext.Provider value={value}>
      {children}
    </CountdownTrialContext.Provider>
  );
};

export default CountdownTrialProvider;
