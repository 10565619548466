export default function textToAlphanumeric(e, oldValue = "") {
  const inputValue = e.target.value;
  const alphanumericRegex = /^[a-zA-Z0-9-_.]*$/;
  return alphanumericRegex.test(inputValue) ? inputValue : oldValue;
}

export function textToAlphaSpace(e, oldValue = "") {
  const inputValue = e.target.value;
  const alphanumericRegex = /^[a-zA-Z ]*$/;
  return alphanumericRegex.test(inputValue) ? inputValue : oldValue;
}

