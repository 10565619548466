const isProduction =
  process.env.NEXT_PUBLIC_APP_ENV === "production" ||
  process.env.NEXT_PUBLIC_APP_ENV === "beta";
const disableProduction = isProduction ? false : true;

export const FEATURE_FLAG = {
  FREE_TRIAL: false,
  STUDIO: false,
  STORE: false,
};
