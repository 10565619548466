import TelegramIcon from "@/assets/social-media/telegram.svg";
import XIcon from "@/assets/social-media/x.svg";

export const SOCIAL_MEDIA_DATA = [
  {
    title: "Telegram",
    id: "telegram_username",
    icon: TelegramIcon.src,
    placeholder: "Enter username",
  },
  {
    title: "X/Twitter",
    id: "twitter_username",
    icon: XIcon.src,
    placeholder: "Enter username",
  },
];
