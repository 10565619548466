export function isValidEmail(text) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(text);
}

export function textToEmail(e, oldValue = "") {
  const inputValue = e.target.value;
  //no space regex
  const emailRegex = /^[^\s]*$/;
  return emailRegex.test(inputValue) ? inputValue : oldValue;
}

