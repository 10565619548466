import { useCallback, useEffect, useMemo, useState } from "react";
import { AuthContext, INITIAL_AUTH_ME_DATA } from "./context";
import { isClientUnauthorized, wagmiConnection } from "@/signal/layout";
import {
  bythenAccount,
  closeModalDialog,
  modalDialog,
} from "@/components/layout";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAuthMe } from "@/service/auth";
import AccountSetupInitialLoad from "@/components/Dialog/AccountSetup/AccountSetupInitialLoad";
import { getModalProps } from "@/components/Modal/ModalDialog/const";
import { getByteDetail } from "@/service/dojo/bytes";
import { postTrackAnalytics } from "@/service/tracking";
import { useRouter } from "next/router";
import { useAccount, useDisconnect } from "wagmi";
import Cookies from "js-cookie";
import { shortenWalletAddress } from "@/utils/shortenWalletAddress";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { bythenLogout } from "@/api/account";

// initialIsByteExist, flag if parent already fetch byteDetail
const PATH_WITHOUT_ACCOUNT_SETUP = ["/store/[slug]", "/store/bythen-card"];
const PATH_WITH_TOU = ["/chat/[roomid]", "/chat", "/mycollection", "/studio"];
export const AuthContextProvider = ({
  children,
  serverToken,
  initialIsByteExist = null,
}) => {
  const {
    access_token,
    account_id,
    wallet_address: walletAddress,
  } = bythenAccount.value || {};
  const [authToken, setAuthToken] = useState(serverToken || access_token);
  const [authMeData, setAuthMeData] = useState(INITIAL_AUTH_ME_DATA);

  const { asPath, pathname } = useRouter();
  const { disconnectAsync } = useDisconnect();
  const { openConnectModal } = useConnectModal();
  const { connector } = useAccount();

  const shortAddress = shortenWalletAddress(walletAddress);

  //Process logout from wallet
  const processLogout = useCallback(async () => {
    try {
      await disconnectAsync(
        { connector },
        {
          onError(e) {
            console.log("🚀 ~ onDisconnect ~ error:", e);
          },
        },
      );
    } catch (e) {
      console.log("🚀 ~ onDisconnect ~ error:", e);
    } finally {
      const requestLogout = async () => {
        try {
          if (bythenAccount.value?.access_token) {
            const { access_token, account_id, account_type } =
              bythenAccount.value || {};
            await bythenLogout({
              access_token,
              account_id,
              account_type,
            });

            window.dataLayer.push({
              user_id: null,
            });
          }
        } catch (error) {
          console.log("🚀 ~ onDisconnect ~ error:", error);
        }
      };

      requestLogout();
      if (Cookies.get("BYTHEN_AUTH")) {
        //remove cookie if user already has old cookie
        Cookies.remove("BYTHEN_AUTH", {
          domain: ".bythen.ai",
          path: "/",
        });

        //remove new cookie
        Cookies.remove("BYTHEN_AUTH");
      }

      bythenAccount.value = "";
      closeModalDialog();
      wagmiConnection.value = false;
    }
  }, [disconnectAsync, connector]);

  const processLogin = useCallback(async () => {
    await processLogout();
    openConnectModal();
  }, [processLogout, openConnectModal]);

  const isAuthorized = !!authToken && !isClientUnauthorized.value;
  const isUnauthorized = !isAuthorized;

  const { data: byteDetail } = useQuery({
    queryFn: getByteDetail,
    queryKey: ["getByteDetail", initialIsByteExist, isAuthorized],
    enabled: initialIsByteExist === null && isAuthorized,
  });

  const isByteExist =
    (!!byteDetail?.build?.byte_data?.byte_id &&
      !!byteDetail?.build?.byte_data?.byte_symbol) ||
    !!initialIsByteExist;

  const {
    data: authMeResponse,
    isFetched: isAuthMeFetched,
    isLoading: isAuthMeLoading,
  } = useQuery({
    queryFn: getAuthMe,
    queryKey: ["getAuthMe", isAuthorized],
    enabled: isAuthorized,
    select: useCallback(
      (data) => {
        console.log("🚀 ~ onSettled:useCallback ~ data:", data);

        const isPageWithTOU = PATH_WITH_TOU.includes(pathname);
        const isPageWithAccountSetup =
          !PATH_WITHOUT_ACCOUNT_SETUP.includes(pathname);

        const isValidTermOfUse =
          !(data?.agreement_approved_at === null) || !isPageWithTOU;

        const isValidAccountSetup =
          !(data?.is_profile_completed === false) || !isPageWithAccountSetup;

        const profileImageUrl = data?.profile_image_url ?? "";
        const randomImage = data?.id ? (data.id % 3) + 1 : 1;

        return {
          ...data,
          tou_bythen_flag: isValidTermOfUse,
          account_setup_flag: isValidAccountSetup,
          profile_image_url: profileImageUrl
            ? profileImageUrl
            : `${process.env.NEXT_PUBLIC_ASSETS_URL}/general/profile-${
                randomImage
              }.png`,
        };
      },
      [pathname],
    ),
  });

  useEffect(() => {
    if (authMeResponse && authMeResponse?.account_setup_flag === false) {
      const onAcceptAccountSetup = () => {
        closeModalDialog();
        setTimeout(() => {
          setAuthMeData((curr) => ({ ...curr, account_setup_flag: true }));
        }, 201);
      };

      modalDialog.value = {
        ...getModalProps({
          backgroundClose: false,
          hideCloseButton: false,
          bodyClassName: "md:pt-10 pt-10",
        }),
        isKeyboardDismissDisabled: true,
        body: <AccountSetupInitialLoad onConfirm={onAcceptAccountSetup} />,
        onClose: processLogout,
      };
    }
  }, [processLogout, authMeResponse]);

  const { mutateAsync: onSendAnalytics } = useMutation({
    mutationFn: postTrackAnalytics,
  });

  useEffect(() => {
    setAuthToken(access_token || null);
  }, [access_token]);

  useEffect(() => {
    if (authMeResponse && authMeResponse.id) {
      window.dataLayer.push({
        user_id: authMeResponse?.id,
      });
      postTrackAnalytics({
        account_id: authMeResponse?.id,
        platform: "web",
        page: asPath,
      });
    }
  }, [authMeResponse, asPath]);

  useEffect(() => {
    setAuthMeData(authMeResponse ? authMeResponse : INITIAL_AUTH_ME_DATA);
  }, [authMeResponse]);

  useEffect(() => {
    if (Cookies.get("BYTHEN_AUTH")) {
      window.dataLayer.push({
        user_id: JSON.parse(Cookies.get("BYTHEN_AUTH"))?.account_id,
      });
    }
  }, []);

  const value = useMemo(
    () => ({
      authToken,
      isUnauthorized,
      isAuthorized,
      isByteExist,
      shortAddress,
      walletAddress,
      isAuthMeFetched,
      isAuthMeLoading,
      authMeData,
      processLogout,
      processLogin,
      setAuthMeData,
    }),
    [
      authToken,
      isUnauthorized,
      isAuthorized,
      isByteExist,
      shortAddress,
      walletAddress,
      isAuthMeFetched,
      isAuthMeLoading,
      authMeData,
      processLogout,
      processLogin,
      setAuthMeData,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
