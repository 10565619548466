import { Select, SelectItem } from "@nextui-org/react";
import { useCallback, useState } from "react";

export const INTEREST_ITEMS = ["Content Creator", "Investor", "Casual User"];

const renderValue = (value, isOpen) => {
  return (
    <div className="flex items-center justify-between text-byteBlack-1000/30">
      <span
        className={!value ? "text-byteBlack-1000/30" : "text-byteBlack-1000"}
      >
        {!value ? "Choose one" : value}
      </span>
      <span className="material-symbols-outlined !text-[12px] text-byteBlack-1000 md:!text-[18px]">
        {isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
      </span>
    </div>
  );
};
export default function InterestDropdown({ setInterestState, interestState }) {
  const [isOpen, setIsOpen] = useState(false);
  const onChange = (e) => {
    const value = e.target.value;
    if (!value) return;
    setInterestState(value);
  };

  return (
    <>
      <p className="font-mono_medium mb-2 font-mono text-[16px] leading-5">
        How Would You Describe Yourself?
      </p>

      <Select
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        aria-label="interest-list-options"
        radius="none"
        placeholder={renderValue(interestState, isOpen)}
        selectorIcon={<></>}
        renderValue={useCallback(
          () => renderValue(interestState, isOpen),
          [isOpen, interestState],
        )}
        classNames={{
          innerWrapper: ["w-full"],
          base: ["w-full", "bg-white", "border-none"],
          popoverContent: [
            "rounded-none",
            "mt-2",
            "border border-byteBlack-1000/70",
            "p-0",
          ],
          listbox: ["p-0"],

          value:
            "text-[14px] leading-5 tracking-wide md:text-[16px] md:leading-6",

          trigger: [
            "h-9 md:min-h-12 md:h-12 py-2 px-4 md:py-3 md:px-4",
            "bg-white",
            "border border-byteBlack-1000/30",
            "data-[hover=true]:bg-white",
            "data-[focus-visible=true]:outline-none data-[focus-visible=true]:outline-offset-0",
            "data-[open=true]:outline-none data-[open=true]:outline-offset-1 data-[open=true] data-[open=true]:border-byteBlack-1000/70",
          ],
        }}
        selectedKeys={interestState ? [interestState] : []}
        onChange={onChange}
      >
        {INTEREST_ITEMS.map((interest) => (
          <SelectItem
            selectedIcon={<></>}
            className="rounded-none px-4 py-2 hover:rounded-none data-[hover=true]:bg-byteBlack-1000/10 md:px-4 md:py-3"
            classNames={{
              title:
                "text-[14px] leading-5 tracking-wide md:text-[16px] md:leading-6",
            }}
            data-lenis-prevent
            key={interest}
          >
            {interest}
          </SelectItem>
        ))}
      </Select>
    </>
  );
}
