import { getAuthCookie } from "@/utils/auth";
import APIError from "./apiError";
import * as Sentry from "@sentry/nextjs";
import { isMobile } from "react-device-detect";
import { isClientUnauthorized } from "@/signal/layout";

const parseJson = async (response) => {
  try {
    const data = await response.json();
    return data || null;
  } catch (e) {
    return null;
  }
};

function getApiHost(endpoint, isClient) {
  const clientApiHost = process.env.NEXT_PUBLIC_BYTHEN_API_URL;
  if (isClient) return clientApiHost;

  const arrText = endpoint.split("/"); // Split the string by "/"
  const scope = arrText[1];

  if (scope === "chat-bot") {
    return process.env.NEXT_PUBLIC_BYTHEN_API_SERVER_CHATBOT_URL;
  }

  return clientApiHost;
}

export default async function fetchApi(endpoint, options = {}) {
  const isClient = typeof window !== "undefined";

  const {
    headers: customHeader,
    method = "GET",
    body,
    req = null,
    isAuth = true,
    customFetchUrl = null,
  } = options || {};

  const { accessToken, walletAddress } = getAuthCookie(req);

  const fetchURL =
    customFetchUrl || `${getApiHost(endpoint, isClient)}${endpoint}`;

  try {
    if (isAuth && !accessToken) {
      throw new APIError("Access token not found", {
        httpCode: 401,
      });
    }

    const lowercaseMethod = method.toLowerCase();
    const isPostOrPut = lowercaseMethod === "post" || lowercaseMethod === "put";

    const response = await fetch(fetchURL, {
      credentials: "include",
      method,
      ...(isPostOrPut && body ? { body: JSON.stringify(body) } : {}),
      headers: {
        "Content-Type": "application/json",
        ...(accessToken && isAuth
          ? { Authorization: `Bearer ${accessToken}` }
          : {}),
        ...customHeader,
      },
    });

    const data = await parseJson(response);

    if (![200, 201].includes(response.status)) {
      const apiErrorResponse = {
        httpCode: response.status,
        ...(data ? data : {}),
      };
      console.log("🚀 ~ fetchApi ~ apiErrorResponse:", apiErrorResponse);
      throw new APIError(`Fetch is not 200`, apiErrorResponse);
    }

    if (isAuth) {
      isClientUnauthorized.value = false;
    }
    return data;
  } catch (e) {
    const logMessage = `Error Fetch ${method}: ${fetchURL}`;
    const httpCode = e?.response?.httpCode;
    const errorResponse = {
      message: e.message,
      accessToken,
      walletAddress,
      body,
      isMobile,
      httpCode,
      ...(e instanceof APIError ? e.response : {}),
    };

    if (!isClient) {
      console.log(logMessage, JSON.stringify(errorResponse));
    } else {
      isClientUnauthorized.value = httpCode === 401;
      Sentry.captureEvent({
        message: logMessage,
        level: "error", // You can set the level (e.g., 'info', 'warning', 'error')
        extra: {
          additionalInfo: errorResponse,
        },
        tags: {
          feature: "fetch-api",
        },
      });
    }
    throw e;
  }
}
