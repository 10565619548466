import { computed, signal } from "@preact/signals-react";

export const wagmiConnection = signal(null);

export const isWagmiLoading = computed(() => wagmiConnection.value === null);

export const isWagmiDisconnected = computed(
  () => wagmiConnection.value === false
);

export const isWagmiConnected = computed(() => wagmiConnection.value === true);

export const isClientUnauthorized = signal(false);

export const chatToken = signal();
