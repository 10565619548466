import { signal } from "@preact/signals-react";

/**
 * [DEPRECATED]
 * triggerAfterConnect (boolean)
 * - true, when user want to trigger an action after connect wallet modal 
 * - false, when user close a connect wallet modal
 * 
 * Use hooks usePreconnectAction to handle this
*/
export const triggerAfterConnect = signal(false);


/**
 * [DEPRECATED]
 * triggerAfterSignWallet (boolean)
 * - true, if previously user success connect wallet and triggerAfterConnect is true
 * - false, when
 *      - User close or disconnect in sign wallet
 *      - Error while authenticate wallet in sign wallet
 *      
 * Use hooks usePreconnectAction to handle this
*/
export const triggerAfterSignWallet = signal(false);