import { Spinner } from "@nextui-org/react";
import BythenButton from "@/components/Button";

export default function AuthenticateWallet({
  isAuthenticating,
  onAuthenticate,
  onDisconnect,
}) {
  return (
    <>
      <div className="mb-8 flex max-w-[314px] flex-col items-center justify-center">
        <div className="mb-4 flex h-14 w-14 items-center justify-center rounded-full bg-byteBlack-1000/10 p-6">
          <span className="material-symbols-outlined material-no-fill !text-[28px] text-[#1C1B1F]">
            lock
          </span>
        </div>
        <h2 className="mb-2 text-center font-founders text-[32px] leading-9 text-byteBlack-1000">
          Sign in your wallet to proceed
        </h2>
        <p className="text-center text-[16px] text-base leading-6 tracking-wide text-byteBlack-1000">
          bythen uses this signature to verify that you&apos;re the owner of
          this Ethereum address.
        </p>
      </div>

      <BythenButton
        type="black"
        onClickButton={onAuthenticate}
        disabled={isAuthenticating}
        classNames="w-full justify-center md:justify-center px-4 md:text-[16px] md:leading-5 mb-3"
      >
        {isAuthenticating && (
          <Spinner size="sm" color="white" className="-ml-[28px] mr-[8px]" />
        )}
        <span>CONTINUE</span>
      </BythenButton>
      <BythenButton
        type="white"
        onClickButton={onDisconnect}
        classNames="w-full justify-center md:justify-center px-4 md:text-[16px] md:leading-5"
      >
        DISCONNECT
      </BythenButton>
    </>
  );
}
