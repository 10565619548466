import fetchApi from "../fetchApi";

export function getAuthMe() {
  return fetchApi(`ms/auth/v1/me`);
}

export function putAuthMe(body) {
  return fetchApi(`ms/auth/v1/me`, {
    method: "PUT",
    body,
  });
}

//DUMMY VALIDATE NICKNAME
export async function getValidateNickname(nickname) {
  //SUCCESS with nickname
  return {
    is_valid: nickname === "user1",
  };
}
