import fetchApi from "./fetchApi";

const BASE_URL = process.env.NEXT_PUBLIC_BYTHEN_API_ANALYTIC;

export function postTrackAnalytics(data) {
  return fetchApi(null, {
    body: data,
    method: "POST",
    customFetchUrl: `${BASE_URL}analytics-dlu`,
    isAuth: false,
  });
}
