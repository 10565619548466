import fetchApi from "@/service/fetchApi";
import { ENVIRONMENT } from "@/utils/environment";

export async function getReferralCode(code) {
  return fetchApi(`ms/web3/v1/cards/validate_referral`, {
    method: "POST",
    body: {
      referral_code: code,
    },
  });
}

export function putTrialBytes(data) {
  return fetchApi(`ms/chat-bot/v1/bytes/trial`, {
    method: "PUT",
    body: data,
  });
}

export function postByteTrial(data) {
  return fetchApi(`ms/chat-bot/v1/bytes/trial`, { body: data, method: "POST" });
}

export function getTrialNftTokens({ symbol }) {
  return fetchApi(`ms/web3/v1/bytes/eligible/trial?symbol=${symbol}`);
}

export function getNftTokens({ symbol }) {
  return fetchApi(`ms/web3/v1/bytes/eligible/mint?symbol=${symbol}`);
}

export function getStaticPdp({ queryKey }) {
  const [_, slug] = queryKey || [];
  return fetchApi(`store/collections/${slug}.json`, {
    customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/collections/${slug}.json`,
    isAuth: false,
  });
}

export function getTrialBytes() {
  const fetchData = async () => {
    try {
      // const trialSlugs = await fetchApi("", {
      //   customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/slugs.json`,
      //   isAuth: false,
      // });
      const trialData = await fetchApi(`ms/chat-bot/v1/bytes/trial`, {
        method: "GET",
      });

      return {
        trial: trialData,
        slugs: [],
      };
    } catch (e) {
      throw e;
    }
  };

  return fetchData();
}

export function getStaticLandingStore() {
  return fetchApi(`store/landing.json`, {
    customFetchUrl: `${ENVIRONMENT.ASSETS_URL}/store/landing.json`,
    isAuth: false,
  });
}

export async function getContractAndSignatureCard(body) {
  try {
    const data = await fetchApi("ms/web3/v1/cards/signature", {
      method: "POST",
      body,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getContractAndSignaturePod(
  data = { symbol: "", tokenId: "" },
) {
  const { symbol, tokenId } = data || {};

  if (!symbol || !tokenId) {
    throw new Error("Symbol and Token ID are required");
  }

  try {
    const data = await fetchApi("ms/web3/v1/pod/signature", {
      method: "POST",
      body: {
        original_token_id: tokenId,
        original_token_symbol: symbol,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
}
