import textToAlphanumeric from "@/utils/form/textToAlphanumeric";
import TextInput from "../TextInput";

export default function NicknameInput({
  state,
  size,
  updateState,
  isReadOnly = false,
}) {
  // const { mutate: checkState } = useMutation({
  //   mutationFn: getValidateNickname,
  //   onSettled: (data, _, variable) => {
  //     if (data?.is_valid === false) {
  //       updateState({
  //         error: variable ? "Sorry, this nickname is already taken." : null,
  //       });
  //     } else if (data?.is_valid === true) {
  //       updateState({ is_valid: true, error: null });
  //     }
  //   },
  // });

  // const debounceCheck = useDebounce(checkState, 2000);

  const handleChange = (e) => {
    const finalTextValue = textToAlphanumeric(e, state.text);
    updateState({ text: finalTextValue, error: null });
  };

  return (
    <TextInput
      errorText={state.error}
      label="Nickname"
      handleChange={handleChange}
      placeholder="Enter nickname"
      infoText="You will not be able to change your nickname later."
      value={state.text}
      size={size}
      maxLength={15}
      isReadOnly={isReadOnly}
    />
  );
}
