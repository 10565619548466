import useAccountSetupForm from "@/hooks/profile/useAccountSetupForm";
import AccountSetupForm, { ButtonAction } from "./AccountSetupForm";

const DialogInfo = ({
  materialIcon,
  title,
  description,
  buttonPrimaryText,
  onClickPrimary,
  iconClassName,
  isLoading,
}) => {
  return (
    <div className="flex flex-col text-center" data-lenis-prevent>
      <span
        className={`material-symbols-outlined material-no-fill !text-[56px] ${iconClassName}`}
      >
        {materialIcon}
      </span>
      <h1 className="mt-3 font-founders text-[28px] font-medium leading-8 md:mt-4 md:text-[32px] md:leading-9">
        {title}
      </h1>
      <p className="mt-2 text-[14px] leading-5 md:text-[16px] md:leading-6">
        {description}
      </p>
      <ButtonAction
        text={buttonPrimaryText}
        onClick={onClickPrimary}
        disabled={isLoading}
      />
    </div>
  );
};

export default function AccountSetupInitialLoad({ onConfirm }) {
  const { isSuccess, submitForm, nicknameState, ...restAccountSetupForm } =
    useAccountSetupForm(onConfirm);

  if (isSuccess) {
    return (
      <DialogInfo
        materialIcon="check_circle"
        title="Account Setup Success"
        description={`Congratulations ${nicknameState.text}, your nickname has been successfully claimed.`}
        buttonPrimaryText="DONE"
        onClickPrimary={onConfirm}
        iconClassName="text-[#139104]"
      />
    );
  }

  return (
    <AccountSetupForm
      submitForm={submitForm}
      nicknameState={nicknameState}
      {...restAccountSetupForm}
    />
  );
}
