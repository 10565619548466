import NicknameInput from "@/components/Profile/components/NicknameInput";
import EmailInput from "@/components/Profile/components/EmailInput";
import SocialMediaInput from "@/components/Profile/components/SocialMediaInput";
import BythenButton from "@/components/Button";
import { useCallback } from "react";
import InterestDropdown from "@/components/Profile/components/InterestDropdown";

export const ButtonAction = ({ text, onClick, disabled }) => {
  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick();
  }, [disabled, onClick]);

  return (
    <BythenButton
      type={disabled ? "grey" : "black"}
      classNames="md:justify-center justify-center py-[14px] px-4 w-full md:text-[16px] md:leading-5 mt-8"
      onClickButton={handleClick}
      disabled={disabled}
    >
      {text}
    </BythenButton>
  );
};

export default function AccountSetupForm(props) {
  const {
    isDisabledForm,
    nicknameState,
    emailState,
    socialMediaState,
    updateEmail,
    updateNickname,
    setSocialMediaState,
    submitForm,
    interestState,
    setInterestState,
  } = props;

  return (
    <div data-lenis-prevent className="pr-1">
      <div className="scollbar-thin -mx-7 max-h-[calc(100dvh-116px)] overflow-y-auto px-7 pr-6 md:-mx-8 md:max-h-[calc(100dvh-100px)] md:px-8 md:pr-7">
        <div className="mb-8">
          <h2 className="mb-2 font-founders text-[32px] leading-9 text-byteBlack-1000">
            Claim your Nickname Now!
          </h2>
          <p>
            Enter your email and social platform account to claim your nickname
            and complete your account setup.
          </p>
        </div>

        <div className="flex flex-col space-y-[18px] md:space-y-6">
          <div>
            <NicknameInput
              state={nicknameState}
              updateState={updateNickname}
              size="sm"
            />
          </div>
          <div>
            <EmailInput
              state={emailState}
              updateState={updateEmail}
              size="sm"
            />
          </div>

          <div>
            <SocialMediaInput
              socialMediaState={socialMediaState}
              setSocialMediaState={setSocialMediaState}
            />
          </div>

          <div>
            <InterestDropdown
              interestState={interestState}
              setInterestState={setInterestState}
            />
          </div>
        </div>

        <div className="mb-2">
          <ButtonAction
            text="CONFIRM"
            onClick={submitForm}
            disabled={isDisabledForm}
          />
        </div>
      </div>
    </div>
  );
}
