import TextInput from "../TextInput";
import { textToEmail } from "@/utils/form/validateEmail";

export default function EmailInput({ state, size, updateState }) {
  const handleChange = (e) => {
    const value = textToEmail(e, state.text);
    updateState({
      text: value,
      error: null,
    });
  };

  return (
    <TextInput
      errorText={state.error}
      label="Email"
      handleChange={handleChange}
      placeholder="Enter email address"
      value={state.text}
      size={size}
    />
  );
}
