import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { useAccount, useSignMessage } from "wagmi";
import { bythenLogin, challenges } from "@/api/account";

import AlertErrorContent from "../Alert/ErrorContent";
import { alertDrawer, bythenAccount, modalDialog } from "../layout";
import { triggerAfterSignWallet } from "./signal";
import { isClientUnauthorized } from "@/signal/layout";
import AuthenticateWallet from "./AuthenticateWallet";
import { useAuthContext } from "@/contexts/auth/context";
export default function SignWallet({ onSuccessSignIn = null }) {
  const { address } = useAccount();
  const { signMessage, data: signatureData } = useSignMessage();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [nonce, setNonce] = useState(null);
  const { query } = useRouter();
  const { processLogout } = useAuthContext();

  useEffect(() => {
    if (signatureData) {
      onLogin();
    }
  }, [signatureData]);

  const setTriggerActionAfterConnect = (status) => {
    triggerAfterSignWallet.value = status;
  };

  const onLogin = async () => {
    try {
      const payload = {
        nonce: nonce,
        wallet_public_key: address,
        signature: signatureData,
      };

      if (query.referral_code) payload.referral_code = query.referral_code;

      const loginResponse = await bythenLogin(payload);

      if (loginResponse && loginResponse.user) {
        Cookies.set(
          "BYTHEN_AUTH",
          JSON.stringify({
            ...loginResponse.user,
            wallet_address: address,
          }),
        );

        bythenAccount.value = {
          ...loginResponse.user,
          fetch_reward: true,
          wallet_address: address,
        };

        modalDialog.value = { ...modalDialog.value, show: false };
        setIsAuthenticating(false);
        isClientUnauthorized.value = false;

        if (onSuccessSignIn) {
          onSuccessSignIn();
        }
      }
    } catch (error) {
      setTriggerActionAfterConnect(false);
      console.log("🚀 ~ onLogin ~ error:", error);
      setIsAuthenticating(false);
      alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={error.message} />,
        },
      ];
    }
  };

  const getNonce = useCallback(async () => {
    const { user } = await challenges({
      wallet_public_key: address,
    });

    if (user) {
      return user.nonce;
    }

    return "";
  }, [address]);

  const onAuthenticateWallet = useCallback(async () => {
    setIsAuthenticating(true);

    try {
      const _nonce = await getNonce(address);
      if (!_nonce) return;
      setNonce(_nonce);

      signMessage(
        {
          message: _nonce,
        },
        {
          onError(e) {
            setIsAuthenticating(false);
            alertDrawer.value = [
              {
                show: true,
                type: "error",
                content: <AlertErrorContent errorMessage={e.message} />,
              },
            ];
            setTriggerActionAfterConnect(false);
          },
        },
      );
    } catch (e) {
      const eror = new Error(e.message);
      setIsAuthenticating(false);
      setTriggerActionAfterConnect(false);
      // e.message is 'Could not parse input'
      // throw eror;
      alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={e.message} />,
        },
      ];
    }
  }, [address, signMessage, getNonce]);

  return (
    <AuthenticateWallet
      isAuthenticating={isAuthenticating}
      onAuthenticate={onAuthenticateWallet}
      onDisconnect={processLogout}
    />
  );
}
