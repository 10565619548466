import Cookies from "js-cookie";

const normalizeAuthCookie = (bythenAuth) => {
  return {
    accessToken: bythenAuth?.access_token ?? null,
    walletAddress: bythenAuth?.wallet_address ?? null,
  };
};

export const getAuthCookie = (request = null) => {
  const payload = {
    accessToken: null,
    walletAddress: null,
  };

  try {
    //Check token from server
    if (request?.cookies?.BYTHEN_AUTH) {
      return normalizeAuthCookie(JSON.parse(request.cookies.BYTHEN_AUTH));
    }

    //Check token from client
    if (typeof window !== "undefined") {
      return normalizeAuthCookie(JSON.parse(Cookies.get("BYTHEN_AUTH")));
    }

    return payload;
  } catch (e) {
    return payload;
  }
};
