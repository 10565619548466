import { createContext, useContext } from "react";

export const CountdownTrialContext = createContext({
    timeLeft: 0,
    isFreeTrialAllowed: false,
    isTrialFetched: false,
    trialData: undefined,
    isTrialLoading: false,
});

export const useCountdownTrialContext = () => useContext(CountdownTrialContext);
