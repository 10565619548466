import { useCallback, useMemo, useState } from "react";
import textToAlphanumeric from "@/utils/form/textToAlphanumeric";
import TextInput from "../TextInput";
import { SOCIAL_MEDIA_DATA } from "../../const/socialMedia";
import { cn } from "@/utils/tailwind";

const SocialMediaOption = ({ item, onSelect }) => {
  const handleSelect = () => {
    onSelect(item);
  };

  return (
    <div
      className="flex w-full cursor-pointer justify-center px-4 py-3 hover:bg-byteBlack-1000/5"
      key={item.id}
      onClick={handleSelect}
    >
      <span>{item.title}</span>
    </div>
  );
};

export default function SocialMediaInput({
  socialMediaState,
  setSocialMediaState,
}) {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const toggleDropdown = useCallback(() => {
    setOpenDropdown((current) => !current);
  }, []);

  const handleSelectSocialMedia = useCallback(({ id }) => {
    setSocialMediaState((current) => ({ ...current, id }));
    setOpenDropdown(false);
  }, []);

  const handleChangeUsername = useCallback((e) => {
    setSocialMediaState((current) => ({
      ...current,
      value: textToAlphanumeric(e, current.value),
    }));
  }, []);

  const { title: selectedTitle } = useMemo(
    () => SOCIAL_MEDIA_DATA.find(({ id }) => id === socialMediaState.id),
    [socialMediaState.id],
  );

  return (
    <div>
      {isOpenDropdown && (
        <div
          onClick={toggleDropdown}
          className="fixed left-0 top-0 z-[999] h-full w-full"
        />
      )}

      <p className="font-mono_medium mb-2 text-[16px] leading-5">
        How to Reach You
      </p>

      <div className="flex space-x-2">
        <div className="relative flex text-[14px] leading-5 md:text-[16px] md:leading-6">
          <div
            className={cn(
              "flex h-[36px] cursor-pointer items-center space-x-2 border-1 border-byteBlack-1000/30 px-4 md:h-[48px]",
              isOpenDropdown && "border-byteBlack-1000",
            )}
            onClick={toggleDropdown}
          >
            <span className="min-w-[56px] md:min-w-[64px]">
              {selectedTitle}
            </span>
            <span className="material-symbols-outlined mt-[2px] !text-[14px] md:!text-[20px]">
              {isOpenDropdown ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </span>
          </div>
          {isOpenDropdown && (
            <div className="absolute left-0 top-9 z-[99999] flex w-full flex-col items-center justify-center border-1 border-t-0 border-byteBlack-1000/30 bg-white md:top-[48px]">
              {SOCIAL_MEDIA_DATA.filter(
                (i) => i.id !== socialMediaState.id,
              ).map((item) => (
                <SocialMediaOption
                  key={item.id}
                  item={item}
                  onSelect={handleSelectSocialMedia}
                />
              ))}
            </div>
          )}
        </div>
        <TextInput
          placeholder="Username"
          value={socialMediaState.value}
          handleChange={handleChangeUsername}
          size="sm"
        />
      </div>
      <p className="mt-2 text-[14px] leading-5 tracking-wide text-byteBlack-1000/50">
        Your preferred platform for our success team to contact you.
      </p>
    </div>
  );
}
