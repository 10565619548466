import fetchApi from "@/service/fetchApi";

export function putByte(data) {
  const { byte_id, byte_symbol, ...rest } = data;
  return fetchApi(`ms/chat-bot/v1/builds/${byte_id}/${byte_symbol}`, {
    method: "PUT",
    body: rest,
  });
}

export function postByte(data) {
  return fetchApi(`ms/chat-bot/v1/builds`, {
    method: "POST",
    body: data,
  });
}

export function getByteDetail(query, req = null) {
  const { byte_id, byte_symbol } = query || {};
  if (byte_id && byte_symbol) {
    return fetchApi(`ms/chat-bot/v1/builds/${byte_id}/${byte_symbol}`, {
      req,
    });
  }

  return fetchApi(`ms/chat-bot/v1/builds/default`, {
    req,
  });
}

export function getByteCollections(req = null) {
  return fetchApi(`ms/chat-bot/v1/bytes`, { req });
}
