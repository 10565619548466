const UNITY_ENVS = {
  local: "staging",
  staging: "staging",
  beta: "production",
  production: "production",
};

const UNITY_FOLDER = {
  beta: "webgl-dojo-beta",
  staging: "webgl-dojo",
  production: "webgl-dojo",
  local: "webgl-dojo",
};

export const ENVIRONMENT = {
  ENV_NAME: process.env.NEXT_PUBLIC_APP_ENV,
  UNITY_ENV_NAME: UNITY_ENVS[process.env.NEXT_PUBLIC_APP_ENV],
  API_STREAM: process.env.NEXT_PUBLIC_STREAM_URL,
  API_TANOOKI: process.env.NEXT_PUBLIC_TANOOKI_API_URL,
  API_BYTHEN: process.env.NEXT_PUBLIC_BYTHEN_API_URL,
  API_SOCKET: process.env.NEXT_PUBLIC_SOCKET_URL,
  ASSETS_URL: process.env.NEXT_PUBLIC_ASSETS_URL,
  STORAGE_URL: process.env.NEXT_PUBLIC_STORAGE_URL,
  BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
  UNITY_FOLDER: UNITY_FOLDER[process.env.NEXT_PUBLIC_APP_ENV],
};
