import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const BUTTON_CLASS = {
  black:
    "bg-[#0E100F] text-white border border-transparent hover:bg-[#FFAF70] [&_svg_path]:hover:fill-[#0E100F]",
  white: "bg-white text-[#0E100F] border border-[#0E100F]",
  grey: "bg-[#F5F3F0] text-[#0E100F4D] border border-transparent",
  red: "bg-elmoRed-200 text-elmoRed-600 border border-transparent hover:text-elmoRed-600 hover:border hover:border-elmoRed-600",
  ghost: "shadow-none",
};

const HOVER_CLASS = {
  default:
    "shadow-[#0E100F] hover:border-[#0E100F] hover:text-[#0E100F] [&_svg_path]:hover:stroke-[#0E100F] hover:scale-[1.01] transition-all duration-300",
  ghost: "hover:bg-byteBlack-1000/5 hover:shadow-none hover:scale-[1]",
};

const BUTTON_CLASS_SIZE = {
  md: {
    BUTTON_SIZE: "px-6 py-[14px] text-base md:text-xl leading-5",
    HOVER_SIZE: "hover:shadow-[6px_6px_0px_0px]",
  },
  sm: {
    BUTTON_SIZE: "px-[15px] py-[10px] text-[14px] leading-[16px] ",
    HOVER_SIZE: "hover:shadow-[4px_4px_0px_0px]",
  },
};
function BythenButton({
  children,
  onClickButton,
  classNames,
  type,
  size = "md",
  disabled = false,
}) {
  const { BUTTON_SIZE, HOVER_SIZE } = BUTTON_CLASS_SIZE[size];
  return (
    <motion.button
      className={twMerge(
        "flex items-center justify-between rounded-none font-founders_semibold uppercase tracking-[0.2px] md:inline-flex md:justify-start",
        disabled
          ? "cursor-not-allowed"
          : `${HOVER_SIZE} ${HOVER_CLASS[type === "ghost" ? "ghost" : "default"]}`,
        BUTTON_SIZE,
        BUTTON_CLASS[type],
        classNames,
      )}
      onClick={onClickButton}
      disabled={disabled}
    >
      {children}
    </motion.button>
  );
}

export default BythenButton;
